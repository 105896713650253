$.extend(true, $.fn.dataTable.defaults, {

    "orderClasses": false,
    "SortClasses": false,
    "deferRender": true,
    "autoWidth": false,
    language: {
      searchPlaceholder: "Search in records...",
      search: "",
    }

    /*"stateSave": true,
    "autoWidth": false,
    "order": [[0, "asc"]],
    "columnDefs": [ {
      "targets": 'no-sort',
      "orderable": false
    } ],
    language: {
      searchPlaceholder: "Search in records...",
      search: "",
    }*/

});

$.extend( $.fn.dataTableExt.oSort, {
  "currency-pre": function ( a ) {
      a = (a==="-") ? 0 : a.replace( /[^\d\-\,]/g, "" );
      return parseFloat( a );
  },

  "currency-asc": function ( a, b ) {
      return a - b;
  },

  "currency-desc": function ( a, b ) {
      return b - a;
  }
} );