/*
    USAGE:
    Show a bunch of hidden element if the have the class ".expandable"
    Also a collection must contain a element containing the hidden element example:
    -----
    <div data-expandable="1">
        <div class="title"> .... </div>
        <div class="summary"> .... </div>
        <div class="description expandable"> .... </div>  <-- This wont be visible initialy
        <div class="clickit" id="trigger-parent">
            <a href="#" class="expandable-trigger visible" data-parent-selector="trigger-parent">Trigger</a>   <-- trigger element, data-parent-selector is optional, if contained, it will hide the parent element as well
            <a href="#" class="expandable-trigger-close visible" data-parent-selector="trigger-parent">Trigger</a>   <-- trigger element visible if is expanded
        </div>
    </div>
    -----
*/

'use strict'

const expandableCollections = [...document.querySelectorAll('[data-expandable="1"]')];

const removeClass = name => element => element.classList.remove(name);
const addClass = name => element => element.classList.add(name);

const showHiddenElements = removeClass('expandable');
const hideElements = addClass('expandable');

const setCollectionVisible = collection => list => state => event => {
    event.preventDefault();
    let triggerElement = event.currentTarget;
    let parent = collection.querySelector(triggerElement.dataset.parentSelector)
    let trigger = collection.querySelector('.expandable-trigger'); // refactor this one day
    let closeTrigger = collection.querySelector('.expandable-trigger-close');     

    if(parent) 
        parent.remove();

    let dummy = (state == 'visible')
        ? list.forEach(showHiddenElements)
        : list.forEach(hideElements); 
        
    if(state == 'visible') // refactor this one day
    {   
        removeClass('invisible')(closeTrigger); 
        addClass('visible')(closeTrigger); 
        
        removeClass('visible')(trigger); 
        addClass('invisible')(trigger); 
    } else {
        removeClass('invisible')(trigger); 
        addClass('visible')(trigger); 
        
        removeClass('visible')(closeTrigger); 
        addClass('invisible')(closeTrigger); 
    
    }
}

const setTrigger = collection => {

    let trigger = collection.querySelector('.expandable-trigger');
    let closeTrigger = collection.querySelector('.expandable-trigger-close');       

    let list = [...collection.querySelectorAll('.expandable')];
    let setVisible = setCollectionVisible(collection)(list)('visible');
    let setInVisible = setCollectionVisible(collection)(list)('invisible');
    
    trigger.addEventListener('click', setVisible);
    closeTrigger.addEventListener('click', setInVisible);
}

const expandable = () => {
    if(expandableCollections)
    {
        expandableCollections.forEach(setTrigger);
    }
}

export default expandable;