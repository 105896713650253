'use strict'

const filterTrigger = document.querySelector('#filtertrigger');
const filter = document.querySelector('#filter');

const setAttribute = (element) => (attribute) => (value) => {
    element.setAttribute(attribute, value);
}

const initMultiSelector = (className) => {
    const element = document.querySelector(className);
    if (element != null) {
        const placeholder = element.getAttribute("placeholder");
        new MultipleSelect(className, {
            placeholder: placeholder
        });
    }
};

const setActivetAttribute = setAttribute(filter)('data-state');

const setFilter = (e) => {
    e.preventDefault();
    let state = filter.dataset.state
        ? setActivetAttribute('')
        : setActivetAttribute('active');
}

module.exports = {
    set: () => {
        if (filter) {
            filterTrigger.addEventListener('click', setFilter);
            initMultiSelector('.multiple--cc');
            initMultiSelector('.multiple--company');
            initMultiSelector('.multiple--client');
            initMultiSelector('.multiple--state');
            initMultiSelector('.multiple--type');
            initMultiSelector('.multiple--currency');
            initMultiSelector('.multiple--actionnumber');
            initMultiSelector('.select__single_signed');
            initMultiSelector('.select__single_client');
            initMultiSelector('.select__single_onhold');
            initMultiSelector('.select__single_user');
            initMultiSelector('.select__single_year');
            initMultiSelector('.select__single_role');
            initMultiSelector('.select__single_active');
            initMultiSelector('.select__single_exemption');
            initMultiSelector('.Select__single_results');
        }
    }
}
