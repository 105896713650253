'use strict'

import Choices from 'choices.js'

const clientSelector = document.getElementById('client_id');

const clientFind = (id) => (current, item) => {
    if(id == item.id)
        current = item;
    return current;
}

const setElementHtml = (selector, value) => document.querySelector(selector).innerHTML = value;

const setClient = (e) => {
    let find = clientFind(e.detail.value); // find by selected ID
    let clientData = clientDataList.reduce(find,false);

    /*let [first, rest] = clientData.addresses;*/
    //setElementHtml('.client div[data-type="address"]', first.address);
    setElementHtml('.client div[data-type="address"]', clientData.billing_address.address);
    //setElementHtml('.client div[data-type="city"]', clientData.billing_address.city.name);
    setElementHtml('.client div span[data-type="zipcode"]', clientData.billing_address.zip_code);
    setElementHtml('.client div span[data-type="city"]', clientData.billing_address.city.name);
    setElementHtml('.client div[data-type="country"]',  clientData.billing_address.city.country.name);
}

const client = () => {
    if(clientSelector)
        clientSelector.addEventListener('change', setClient);
}

export default client;