'use strict'

const selectorTriggers = [...document.querySelectorAll('[data-selector-target]')]

const selectorCallbackList = [{
    type: 'checkbox',
    callback: setCheckbox 
}];

const setCheckbox = state => element => element.checked = state; 

const setSelectorTarget = (e) => {
    e.preventDefault();
    let selectorTarget = e.currentTarget.dataset.selectorTarget;  
    let state = e.currentTarget.dataset.state == "true" // if state == true, then new state = false
        ? false
        : true;   

    let elementList = [...document.querySelectorAll(selectorTarget)]; 
    elementList.forEach(element => setCheckbox(state)(element));
    e.currentTarget.dataset.state = state;
}

module.exports = {
    set: () => {
        selectorTriggers.forEach(element => element.addEventListener('click', setSelectorTarget));
    }
}