'use strict'

import Choices from 'choices.js'

const defaultOptions = {
    /* allowEmptyOption: true, */
    noResultsText: 'No results found',
    noChoicesText: 'No results to choose',
    itemSelectText: 'Select',
    shouldSort: false,
    removeItems: true,
    removeItemButton: true
};

const createChoicesSelectbox = options => element => new Choices(element, options);

const getList = htmlElement => selector => [...htmlElement.querySelectorAll(selector)];

const createSelectboxes = htmlElement => options => selector => {
    const listOfElements = getList(htmlElement);
    const formSelectList = listOfElements(selector);
    const selctboxWithOptions = createChoicesSelectbox(options);
    return formSelectList.map(selctboxWithOptions);
}

export const selectbox = (selector) => {
    let createWithHtmlDocument = createSelectboxes(document);
    let createWithOptions = createWithHtmlDocument(defaultOptions);
    return createWithOptions(selector);
}

export const selectboxContent = (customHtml, selector) => {
    let createWithCustomHtmlDocument = createSelectboxes(customHtml);
    let createWithOptions = createWithCustomHtmlDocument(defaultOptions);
    return createWithOptions(selector);
}
