'use strict'
var deliveries = $('.data-table .deliveries');

var checkboxToForm = (e) => {
	var element = $(e.target);
	var client_id = element.data('clientid');
	var currency_id = element.data('currencyid');
	var country = element.data('country');
	var direct = element.data('direct');
	var rental = element.data('rental');
	var value = element.val()

	var find = $(`#checkbox_values [name="invoiceIds[${client_id}][${currency_id}][]"][value="${value}"]`).length;
	var html = `<input type="hidden" value="${value}" name="invoiceIds[${client_id}][${currency_id}][]"/>`;
	if(!find && element.is(':checked')) {
		$('#checkbox_values').append(html);
		delivery_array[element.val()].checked = true;
		setDisabled(client_id, country, direct, rental);
	}
	if(find && !element.is(':checked')) {
		$(`#checkbox_values [name="invoiceIds[${client_id}][${currency_id}][]"][value="${value}"]`).remove();
		delivery_array[element.val()].checked = false;
		setDisabled(client_id, country, direct, rental);
	}

	var find = $(`#checkbox_values_proforma [name="proFormaInvoiceIds[${client_id}][${currency_id}][]"][value="${value}"]`).length;
	var html = `<input type="hidden" value="${value}" name="proFormaInvoiceIds[${client_id}][${currency_id}][]"/>`;
	if(!find && element.is(':checked')) {
		$('#checkbox_values_proforma').append(html);
	}
	if(find && !element.is(':checked')) {
		$(`#checkbox_values_proforma [name="proFormaInvoiceIds[${client_id}][${currency_id}][]"][value="${value}"]`).remove();
	}
}

var setDisabled = (client_id, country, direct, rental) => {
    var checked = false
	for(var i in delivery_array) {
        var data = delivery_array[i];
        if(data.checked)
        	checked = true;
    }
	if(checked) {
		$('.invoice-ids-input').attr('disabled', 'disabled');
		$(`.invoice-ids-input[data-clientId="${client_id}"][data-country="${country}"][data-direct="${direct}"][data-rental="${rental}"]`).removeAttr('disabled');
	} else
		$('.invoice-ids-input').removeAttr('disabled');
}

var setCustomInputToForm = (data) => {
	var html = `<input type="hidden" value="${data.id}" name="invoiceIds[${data.client_id}][${data.currency_id}][]"/>`;
	if(data.checked)
		$('#checkbox_values').append(html);
	if(!data.checked)
		$(`#checkbox_values [name="invoiceIds[${data.client_id}][${data.currency_id}][]"][value="${data.id}"]`).remove();

	var html = `<input type="hidden" value="${data.id}" name="proFormaInvoiceIds[${data.client_id}][${data.currency_id}][]"/>`;
	if(data.checked)
		$('#checkbox_values_proforma').append(html);
	if(!data.checked)
		$(`#checkbox_values_proforma [name="proFormaInvoiceIds[${data.client_id}][${data.currency_id}][]"][value="${data.id}"]`).remove();
}

var setSelected = () => {
	setTimeout(function() {
		var total_checked = false;
		for(var i in delivery_array) {
			var data = delivery_array[i];
			if(data.checked) {
				$(`.invoice-ids-input[value=${data.id}]`).prop("checked", true);
				$('.invoice-ids-input').attr('disabled', 'disabled');
				$(`.invoice-ids-input[data-clientId="${data.client_id}"][data-country="${data.country}"][data-direct="${data.direct}"][data-rental="${data.rental}"]`).removeAttr('disabled');
				total_checked = true;
			} else if(!data.checked)
				$(`.invoice-ids-input[value=${data.id}]`).prop("checked", false);

		}
		if(!total_checked)
			$(`.invoice-ids-input`).removeAttr('disabled');
	}, 150)
}

export default {
	set: () => {
		if(deliveries) {
			$(".invoice-ids-input").prop("checked", false);
			$('.invoice-ids-input').on('change', checkboxToForm)
			$('#deliveries').on('page.dt', setSelected)
		}
	}
}
