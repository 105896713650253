'use strict'

const compose = (...fns) => fns.reduce((f, g) => (...args) => f(g(...args)))

const dataTableTriggerList = [...document.querySelectorAll('.data-table [data-link]')]

const setWindow = link => window.location.href = link;

const triggerCallback = (e) => {
    let target = e.target;

    if((target.classList.contains('column') && !target.classList.contains('checkbox')) || (target.tagName.toLowerCase() == 'h3')) // if it is has a column class or h3 element
    {
        e.preventDefault();
        setWindow(e.currentTarget.dataset.link);
    }
}

const setTrigger = trigger => trigger.addEventListener('click', triggerCallback); // add eventListener

const dataTable = () => {
    dataTableTriggerList.forEach(setTrigger);
}

export default dataTable;
