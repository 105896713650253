'use strict'

const selectPage = (e) => {
	e.preventDefault();
    var element = $(e.currentTarget);
    var id = element.data('invoice');
    var type = element.data('type');
    var links = pdf_links[id];
    var index = links.findIndex(x => x.active === true);
    var link = pdf_links[id][index];
    var length = pdf_links[id].length - 1;
    if(type == 'next' && index !== length) {
        link.active = false;
        index += 1;
        var newLink = pdf_links[id][index];
        newLink.active = true;
        $(`.pdf-sample[data-id="${id}"] #pdf-viewer`).attr('data', newLink.url);
    }

    if(type == 'prev' && index !== 0) {
        link.active = false;
        index -= 1;
        var newLink = pdf_links[id][index];
        newLink.active = true;
        $(`.pdf-sample[data-id="${id}"] #pdf-viewer`).attr('data', newLink.url);
    }

    if(index == 0)
    	$(`.document-page-button.prev[data-invoice="${id}"]`).attr('disabled', true); //$('.document-page-button.prev').hide();
    else if(index > 0)
        $(`.document-page-button.prev[data-invoice="${id}"]`).attr('disabled', false); //$('.document-page-button.prev').show();

    if(index == length)
        $(`.document-page-button.next[data-invoice="${id}"]`).attr('disabled', true); //$('.document-page-button.next').hide();
    else if(index !== length)
        $(`.document-page-button.next[data-invoice="${id}"]`).attr('disabled', false); //$('.document-page-button.next').show();
}

export default {
    set: () => {
        $('.document-page-button').on('click', selectPage);
        $('.document-page-button.prev').attr('disabled', true);
        setTimeout(function() {
            var invoice_id = $('.content[data-invoice-id]').data('invoiceId');
            if(invoice_id) {
            	if(pdf_links.length) {
                	if(pdf_links[invoice_id].length <= 1) {
                    	$('.document-page-button.next').attr('disabled', true);
                	}
                }
                else
                {
                	$('.document-page-button.prev').attr('disabled', true);
                	$('.document-page-button.next').attr('disabled', true);
                }
            }
        }, 500);
    }
}