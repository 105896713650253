'use strict'

import Choices from 'choices.js'

const defaultOptions = {
    noResultsText: 'No results found',
    noChoicesText: 'No results to choose',
    itemSelectText: 'Select',
    shouldSort: true,
    removeItems: true,
    removeItemButton: true
}

const createChoicesSelect = (element, options) => new Choices(element, options);
// Set selected..
const setChoicesSelect = (select, choices) => select.setChoices(choices);

const clientFind = (id) => (current, item) => {
    if(id == item.id)
        current = item;
    return current;
}

const setElementHtml = selector => value  => {
	if(document.querySelector(selector) && value)
		document.querySelector(selector).innerHTML = value;
}

const getClient = id => {
	let find = clientFind(id);
	return clientDataList.reduce(find,false);
}

const getClientDeliveryAddresses = id => {
	return deliveryAddressList.filter(item => (id == item.id));
}

const setClientAddress = setElementHtml('.client div[data-type="address"]');
const setClientZipcode = setElementHtml('.client div span[data-type="zipcode"]');
const setClientCity = setElementHtml('.client div span[data-type="city"]');
const setClientProvince = setElementHtml('.client div span[data-type="province"]');
const setClientCountry = setElementHtml('.client div[data-type="country"]');

// Rows
const reasonSelectorRow = document.getElementById('credit_reason');
const ukExchangeRateRow = document.getElementById('uk_exchange_rate_row');
const plExchangeRateRow = document.getElementById('pl_exchange_rate_row');

// Selectboxes
const billingTemplateTypeSelector = document.getElementById('billing_template_type_id');
const billingTypeSelector = document.getElementById('billing_type_id');
const deliverySelector = document.getElementById('delivery_client_id');
const clientSelector = document.getElementById('client_new_id');
const deliveryAddressSelector = document.getElementById('delivery_new_id');
const deliveryCitySelector = document.getElementById('delivery_city_id');
const currencySelector = document.getElementById('currency_new_id');
const invoiceTypeSelector = document.getElementById('invoice_type_id');
const reasonSelector = document.getElementById('credit_reason_id');

const newDeliveryClientSelector = document.getElementById('new_delivery_client_id');
const newCitySelector = document.getElementById('new_delivery_city_id');

// Delivery address inputs
const deliveryAddressName = document.getElementById('delivery_name');
const deliveryAddressLine = document.getElementById('delivery_address');
const deliveryAddressLine2 = document.getElementById('delivery_address2');
const deliveryAddressZipcode = document.getElementById('delivery_zip_code');

// new Delivery address inputs
const setNewDeliveryName = document.getElementById('new_delivery_name');
const setNewDeliveryAddress = document.getElementById('new_delivery_address');
const setNewDeliveryAddress2 = document.getElementById('new_delivery_address2');
const setNewDeliveryZipcode = document.getElementById('new_delivery_zip_code');

// set value for a select
const setSelectByValue = (select, value) => select.setChoiceByValue(value);

/**
 * setDeliveryAddress
 * Update delivery address
 * @param {address} object with all address properties
 * @param {countrySelect} object containing a choices select
 * @returns {void}
 * */
const setDeliveryAddress = (address, countrySelect) => {
	deliveryAddressName.value = address.name || '';
	deliveryAddressLine.value = address.address || '';
	deliveryAddressLine2.value = address.address2 || '';
	deliveryAddressZipcode.value = address.zipcode || '';
	setSelectByValue(countrySelect, address.country);
}

const setDeliveryAddresSelector = (list,deliverySelect) => {
	// clear all current selected items
	let [first] = list;
	deliverySelect.removeHighlightedItems();
	deliverySelect.removeActiveItems();
	deliverySelect.unhighlightAll();
	deliverySelect.setChoices(list, undefined, undefined, true);
	setSelectByValue(deliverySelect, first.id);
}

/**
 * setClientAddressProperties
 * Update Client address data
 * @param {address} object with all address properties
 * @returns {void}
 * */
const setClientAddressProperties = address => {
	setClientAddress(address.address);
	setClientZipcode(address.zip_code);
	setClientCity(address.city);
	setClientProvince(address.province);
	setClientCountry(address.country);
};

/**
 * initDefault()
 * Initialize default actions
 * @returns {void}
*/
const initDefault = () => {

	// setup choices selectors
	let selectBillingTemplateType = createChoicesSelect(billingTemplateTypeSelector, Object.assign({}, defaultOptions));
	let selectBillingType = createChoicesSelect(billingTypeSelector, Object.assign({}, defaultOptions));

	const createSelectCurrency = createChoicesSelect(currencySelector, Object.assign({}, defaultOptions));
	let selectCurrency = setChoicesSelect(createSelectCurrency, invoiceCurrencyList);
	let selectReason = createChoicesSelect(reasonSelector);
	
	const createSelectDeliveryCity = createChoicesSelect(deliveryCitySelector, Object.assign({},defaultOptions))
	let selectDeliveryCity = setChoicesSelect(createSelectDeliveryCity, invoiceCityList);

	const createSelectClient = createChoicesSelect(clientSelector, Object.assign({},defaultOptions));
	let selectClient = setChoicesSelect(createSelectClient, invoiceClientList);

	const createSelectDelivery = createChoicesSelect(deliverySelector, Object.assign({},defaultOptions));
	let selectDelivery = setChoicesSelect(createSelectDelivery, invoiceClientList);
	
	let selectClientDeliveryAddresses = createChoicesSelect(deliveryAddressSelector, Object.assign({},defaultOptions, {choices : deliveryAddressList}));
	

	// addEventListeners
	clientSelector.addEventListener('change', event => {
		let value = event.detail.value;
		let client = getClient(value);

		// update billing template type, billing type and currency
		setSelectByValue(selectBillingTemplateType, String(client.company.billing_template_type_id));
		setSelectByValue(selectBillingType, String(client.company.billing_type_id));
		setSelectByValue(selectCurrency, client.company.currency_id);

		var event = new Event('change');
		billingTypeSelector.dispatchEvent(event);

		let clientProvince = '';  
		if (client.billing_address.city.province !== null) {
			clientProvince = client.billing_address.city.province.name;
		}

		// set client Address
		setClientAddressProperties({
			address: client.billing_address.address,
			zip_code: client.billing_address.zip_code,
			city: client.billing_address.city.name,
			province: clientProvince,
			country: client.billing_address.city.country.name
		});

		if(deliverySelector.value == '') // if first hit update delivery properties
		{
			setSelectByValue(selectDelivery, client.id);
			setDeliveryAddress({
				name: client.company.name,
				address: client.billing_address.address,
				address2: client.billing_address.addres2,
				zipcode: client.billing_address.zip_code,
				country: client.billing_address.city.managementId
			}, selectDeliveryCity);

			// filter for delivery addresses;
			let clientDeliveryAddressList = getClientDeliveryAddresses(client.managementId);
			setDeliveryAddresSelector(clientDeliveryAddressList, selectClientDeliveryAddresses);
		}
		setVat();
	});

	deliverySelector.addEventListener('change', event => {
		let value = event.detail.value;
		let client = getClient(value);

		// filter for delivery addresses;
		let clientDeliveryAddressList = getClientDeliveryAddresses(client.managementId);
		setDeliveryAddresSelector(clientDeliveryAddressList, selectClientDeliveryAddresses);

		// set Delivery address inputs
		setDeliveryAddress({
			name: client.company.name,
			address: client.billing_address.address,
			address2: client.billing_address.addres2,
			zipcode: client.billing_address.zip_code,
			country: client.billing_address.city.managementId
		}, selectDeliveryCity);

		if(clientSelector.value == '0') // if first hit update delivery properties
		{
			// update currency
			setSelectByValue(selectCurrency, client.currency_id);

			let clientProvince = '';  
			if (client.billing_address.city.province !== null) {
				clientProvince = client.billing_address.city.province.name;
			}

			// set client Addres
			setClientAddressProperties({
				address: client.billing_address.address,
				zip_code: client.billing_address.zip_code,
				city: client.billing_address.city.name,
				province: clientProvince,
				country: client.billing_address.city.country.name
			});

			setSelectByValue(selectClient, client.id);
		}
		setVat();
	});

	deliveryAddressSelector.addEventListener('change', event => {
		let value = event.detail.value;
		let address = deliveryAddressList.reduce((accumulator, current) => {
			if(current.value == value )
				accumulator = current;
			return accumulator;
		}, false);

		if(address)
			setDeliveryAddress({
				name: address.info[0],
				address: address.info[1],
				address2: address.info[2],
				zipcode: address.info[3],
				country: address.info[4]
			}, selectDeliveryCity);

		setVat();
	});

	invoiceTypeSelector.addEventListener('change', event => {
		if(invoiceTypeSelector.options[invoiceTypeSelector.selectedIndex].text == 'Credit') {
			reasonSelectorRow.style.display = "block";
			selectReason.destroy();
            reasonSelector.disabled = false;
            reasonSelector.required = true;
            selectReason = createChoicesSelect(reasonSelector)
		} else {
			reasonSelectorRow.style.display = "none";
			selectReason.destroy();
            reasonSelector.disabled = true;
            reasonSelector.required = false;
            selectReason = createChoicesSelect(reasonSelector)
		}
	})

	billingTypeSelector.addEventListener('change', event => {
		if(billingTypeSelector.options[billingTypeSelector.selectedIndex].text == 'UK') {
			ukExchangeRateRow.style.display = "block";
		} else if(billingTypeSelector.options[billingTypeSelector.selectedIndex].text == 'Poland') {
			plExchangeRateRow.style.display = "block";
		} else {
			ukExchangeRateRow.style.display = "none";
			plExchangeRateRow.style.display = "none";
		}
	})

	reasonSelectorRow.style.display = "none";
	ukExchangeRateRow.style.display = "none";
	plExchangeRateRow.style.display = "none";

	/*var event = new CustomEvent('change', {
	  detail: {
	    key: 'detail-value'
	  }
	})
	clientSelector.dispatchEvent(event);*/
}

/**
 *
 * initNew()
 * Initialize new only at editing a invoice
 * @returns {void}
*/
const initNew = () => {
	let selectDeliveryCity = createChoicesSelect(newCitySelector, Object.assign({},{noResultsText: 'No results found', noChoicesText: 'No results to choose', itemSelectText: 'Select', shouldSort: true, removeItems: true, removeItemButton: true}, {choices: invoiceCityList}));
	let selectClient = createChoicesSelect(newDeliveryClientSelector, Object.assign({},defaultOptions, { choices : invoiceClientList}));
	newDeliveryClientSelector.addEventListener('change', e => {
		let value = e.detail.value;
		let client = getClient(value);

		setNewDeliveryName.value = client.billing_address.name;
		setNewDeliveryAddress.value = client.billing_address.address;
		setNewDeliveryAddress2.value = client.billing_address.address2;
		setNewDeliveryZipcode.value = client.billing_address.zip_code;
		setSelectByValue(selectDeliveryCity, client.billing_address.city.id);
	});
	setVat();
}

const clientSelectbox = (e) => {	
	if(deliverySelector) { // new invoice
		initDefault();
	}

	if(newDeliveryClientSelector) { // when editing a invoice
		initNew();
	}
}

const setVat = () => {
	let client = getClient($('#client_new_id').val());
	if(client.hide_vat == 1) {
		$('#invoice_show_vat').attr('checked', false);
		$('#invoice_show_vat').attr('disabled', true);
		$('#invoice_show_vat').attr('title', 'Fiscal Unity');
		$('.edit_vat').val(0);
		$('.edit_vat').attr('disabled', true);
		$('.edit_vat').attr('title', 'Fiscal Unity');
	} else {
		//$('#invoice_show_vat').attr('checked', true);
		$('#invoice_show_vat').attr('disabled', false);			
		$('#invoice_show_vat').removeAttr('title');
		$('.edit_vat').attr('disabled', false);
		$('.edit_vat').removeAttr('title');
		
	    var companyId = $('#companyId').val();
	    var country = $('#delivery_city_id').text();
	    if(companyId == 1 && country) {
			if(country.indexOf("The Netherlands") >= 0)
		        $('#invoice_show_vat').prop('checked', true);
		    else
		    	$('#invoice_show_vat').prop('checked', false);
		} else if(companyId == 2 && country) {
			if(country.indexOf("USA") >= 0)
		        $('#invoice_show_vat').prop('checked', false);
		    else
		    	$('#invoice_show_vat').prop('checked', false);
		} else if(companyId == 3 && country) {
			if(country.indexOf("USA") >= 0)
		        $('#invoice_show_vat').prop('checked', true);
		    else
		    	$('#invoice_show_vat').prop('checked', false);
		}
	}
}

$('body').on('focus', '#qty', function(e){
   if($(this).val() == 0)
		$(this).val('');
});

$('body').on('blur', '#qty', function(e){
   if($(this).val() == '')
		$(this).val(0);
});

$('body').on('change', '#delivery_city_id, #delivery_zip_code', function(e){
	setVat();
});

export default clientSelectbox;
