'use strict'

const nav = document.querySelector('header#main nav');

let navLinks = false;
if(nav)
	navLinks = [...nav.querySelectorAll('a')];

const addActiveClass = (element) => element.classList.add('active');

const filterActive = (current, element) => {
    if(nav.dataset.active == element.dataset.name)
	    addActiveClass(element);
}

const setNavigationActive = () => {
	if(nav) {
	    navLinks.reduce(filterActive, false);
	}
}

module.exports = {
    set: () => setNavigationActive()
}
