const approved = $('#approvedPageForm');

const setCheckboxAction = (e) => {
    var element = $(e.currentTarget);
    var value = element.val();
    var current_data = invoices[value];
    var type = current_data.type;
    var clientId = current_data.client_id;
    var anyChecked = false
    var checked = element.is(':checked');
    if(checked) invoices[value].checked = true
    else invoices[value].checked = false

    for(var i in invoices) {
        var data = invoices[i];
        if(data.checked)
            anyChecked = true;
    }

    if(!anyChecked)
        setNormalView();

    if(type === 'mail' && checked)
        setMailView(clientId, value);
    else if (type === 'print' && checked)
        setPrintView();

    if(type === 'mail' && !checked)
        $(`#invoice-mail-${value}`).remove();
}

const setPrintView = () => {
    $(`.check-ids-input[data-type="mail"]`).attr('disabled', 'disabled');
    $('#checkboxPrintAttachments').show();
    $('#checkboxPrint').show();
    $('#checkboxMail').hide();
    $('.notBulk').hide();
}

const setMailView = (clientId, id) => {
    $('.check-ids-input').attr('disabled', 'disabled');
    $(`.check-ids-input[data-type="mail"][data-client-id="${clientId}"]`).removeAttr('disabled');
    $('#checkbox_values').append(`<input id="invoice-mail-${id}" type="hidden" value="${id}" name="invoiceCollectorIds[]">`)
    $('#checkboxPrintAttachments').hide();
    $('#checkboxPrint').hide();
    $('#checkboxMail').show();
    $('.notBulk').hide();
}

const setNormalView = () => {
    $(`.check-ids-input`).removeAttr('disabled');
    $('#checkboxPrintAttachments, #checkboxPrint, #checkboxMail').hide();
    $('.notBulk').show();
}

const checkActivecheckboxes = () => {
    var checked = false;
    for(var i in invoices) {
        var data = invoices[i];
        if(data.checked) {
            checked = true;
            if(data.type === 'mail')
                setTimeout(function() { setMailView(data.client_id, data.id); }, 150);
            else if(data.type === 'print')
                setTimeout(function() { setPrintView(); }, 150);
            return;
        }
    }
    if(!checked)
        setTimeout(function() { setNormalView(); }, 150);

}

const printMultAttachments = () => {
	printMultipleAttachments();
}

const printMult = () => {
	printMultiple();
}

const createPrintAllAttachmentsBtn = () => {
    var printButton = '<a class="button expandable" data-color="blue" title="Print"><span class="fas fa-print color--white icon--only"></span></a>';
    $('body').on('change', '.check-ids-input', setCheckboxAction);

    setTimeout(() => {
        $('<div id="checkboxActionButton"></div>').insertAfter('.dataTables_info');
        $('#checkboxActionButton').append('<a id="checkboxPrintAttachments" class="button expandable hide" data-color="blue" title="Print"><span class="fas fa-print color--white icon--only"></span>&nbsp; attachments</a>');
        $('#checkboxActionButton').append('<a id="checkboxPrint" class="button expandable hide" data-color="blue" title="Print"><span class="fas fa-print color--white icon--only"></span></a>');
        $('#checkboxActionButton').append('<button type="submit" form="sendMails" id="checkboxMail" class="button" data-color="green" title="Mail"><span class="fas fa-envelope-open-text color--white icon--only"></span></button>');
        $('#checkboxPrintAttachments, #checkboxPrint, #checkboxMail').hide();
        $('#checkboxPrintAttachments').on('click', printMultAttachments);
        $('#checkboxPrint').on('click', printMult);
        $('#approved').on('page.dt', checkActivecheckboxes);
    }, 500);
}

export default {
	set: () => {
		if(approved) {
		    createPrintAllAttachmentsBtn();
		}
	}
}
