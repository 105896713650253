'use strict'

const packageList = [...document.querySelectorAll('*[data-show-package]')];

const getGroup = (name) => {
    return packageList.filter( item => item.dataset.showPackage == name); 

}; 

const addClass = name => element => element.classList.add(name); 
const addParentClass = addClass('show-package-parent'); 

const getFirst = (arr) => { 
    let [head, ...tail] = arr; 
    return head;
};

const showPackage = () => {
    
    let showPackageList = packageList.map(item => item.dataset.showPackage);
    let uniqueValues = [...new Set(showPackageList)]; 

    let result = uniqueValues.map(getGroup)
        .map(getFirst)
        .forEach(addParentClass);
    
}

export default showPackage;