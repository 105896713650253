/** USAGE **/
/* How to load content (html element) in a popup */ 
/* Add the following attribute on your trigger element, [data-popup-content="#a_element_id"] */
/* Put your content in a hidden div, like: */
/* <div class="hidden" id="a_element_id"><div>Your beautiful content</div></div> */
/* If the content needs to change use the setContent function popupElement.content(#your_element) */ 

'use strict'

import { selectboxContent }  from '../selectbox/selectbox.js'; 

const popupElement = document.querySelector('.popup');
const popupContent = document.querySelector('.popup .popup-inner');
const popupCloseTrigger = document.querySelector('.popup .popup-close a');
const popupDefaultTriggers = [...document.querySelectorAll('*[data-popup-content]')];
const popupVault = {}; 

const setState = value => popupElement.dataset.state = value;

const popupVaultAdd = element => { 
    popupVault.element = element;
    popupVault.parent = element.parentNode;
}

const popupVaultRestore = () => {
    popupVault.parent.appendChild(popupVault.element); 
}

const setContent = content => { 
    popupContent.innerHTML = "";
    popupContent.appendChild(content); 
}

const setType = (type) => {
    popupElement.setAttribute('data-type', type); 
}

const getAttribute = attributeName => element => element.getAttribute(attributeName);

const getType = element => getAttribute('data-popup-type')(element); 
const getTargetElement = element => getAttribute('data-popup-content')(element); 

const setTrigger = () => {
    popupCloseTrigger.addEventListener('click', (e) => {
        e.preventDefault();
        if(popupVault.element)
        {
            popupVaultRestore();
            popupContent.innerHTML = "";
        }

        setState('inactive');
    });
}

const setSelectbox = htmlElement => selectboxContent(htmlElement, 'select[data-choices-popup="1"]');

const updateContent = source => {
    let fragment = document.createDocumentFragment();
    setSelectbox(source);
    fragment.appendChild(source);
    return fragment; 
}

const setByTrigger = (e) => {
    e.preventDefault();

    let type = getType(e.currentTarget);
    let targetId = getTargetElement(e.currentTarget);
    let targetElement = document.querySelector(targetId); 
    popupVaultAdd(targetElement);

    let content = updateContent(targetElement); 

    if(content) {
        setType(type);
        setContent(content);
        setState('active');
        setTrigger();
    }
}

export const popup = {

    content: (element) => {
        setContent(element);
    },

    // open popup
    open: (element) => {
        copy()
        setContent(element);
        setState('active');
        setTrigger();
    },

    // change current content
    change: (element) => {
        setContent(element);
    },

    // Set popup type: error, warning, success
    type: (type) => {
        setType(type);
    },

    // close popup
    close: () => {
        setState('inactive');
    },

    setDefaultTriggers: () => {
        popupDefaultTriggers.forEach(element => {
            element.addEventListener('click', setByTrigger); 
        });
    }
}; 
