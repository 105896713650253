'use strict'

import clientSelector from './client_selector.js';
import showPackage from './show_package.js';

const invoice = document.querySelector('.collective-invoice');
const saveTrigger = document.querySelector('.collective-navigation .save-trigger');
const deliveryTriggers = [...document.querySelectorAll('.collective-invoice .delivery:not(.custom)')];
const editTrigger = [...document.querySelectorAll('.collective-invoice .product .edit-trigger')];
const editForms = [...document.querySelectorAll('.collective-invoice .product')];

const newProduct = document.getElementById('product-new');
const newProductTrigger = $('#product-new .trigger .button');
const cancelProduct = $('.product-new-cancel');

const addClass = (classname) => (element) => element.classList.add(classname);
const removeClass = (classname) => (element) => element.classList.remove(classname);

const addActiveClassTo = addClass('active');
const removeActiveClassFrom = removeClass('active');

const setDetailScreen = element => addActiveClassTo(element);
const showPdf = id => $(`.delivery-invoices-product--pdf-sample[data-id="${id}"]`).show();

const setInvoiceDetails = (e) => {
    e.preventDefault();
    // find clicked element
    let productDetailId = collectiveInvoice.reduce((current, item) => {
        if(e.currentTarget.dataset.target == item.elementId)
            current = item.elementId;
            return current;
    }, false);

    setDetailScreen(document.querySelector(productDetailId));

    // set other elements in active
    let inActiveList = collectiveInvoice.filter(item => item.elementId != productDetailId)
        .map(item => removeActiveClassFrom(document.querySelector(item.elementId)));
}

const setProductFormVisible = (e) => {
    e.preventDefault();

    let target = e.currentTarget.dataset.target;
    let element = document.getElementById(target);

    let active = element.classList.contains('active')
        ? removeActiveClassFrom(element)
        : addActiveClassTo(element);
}

const setNewProductFormVisible = (e) => {
    e.preventDefault();
    $(e.target).closest('.product-new').addClass('active');
    $('.product-new.active').find(".invoiceProduct-new").attr("disabled", false);
}

const setCancelProductFormVisible = (e) => {
    e.preventDefault();
    $('.product-new.active').find(".invoiceProduct-new").attr("disabled", true);
    $(e.target).closest('.product-new').removeClass('active');
}

const setDeliveryActive = (e) => {
    $('.delivery').each(function(){
        $(this).removeClass('active');
    })

    $('.delivery-invoices-product--pdf-sample').hide();

    $(e.target).parents('.delivery').addClass('active');
    var clicked_id = $(e.target).parents('.delivery').attr('id');
    var invoice_id = $(e.target).parents('.delivery').data('invoiceId');
    resetPdfPage(invoice_id);
    showPdf(invoice_id);
    Cookies.set(collector, clicked_id, { expires: 0.5 });
}

const resetPdfPage = (id) => {
    var count = 0;
    if(typeof pdf_links[id] !== 'undefined')
    {
        $(`.document-page-button.next[data-invoice="${id}"]`).show();
        $(`.document-page-button.prev[data-invoice="${id}"]`).show();
        if(pdf_links[id].length > 1) {
            for(let link of pdf_links[id]) {
                link.active = count ? false : true;
                count++;
            }
            $(`.document-page-button.next[data-invoice="${id}"]`).attr('disabled', false);
        } else
            $(`.document-page-button.next[data-invoice="${id}"]`).attr('disabled', true);

        $(`.document-page-button.prev[data-invoice="${id}"]`).attr('disabled', true);
        if(pdf_links[id][0])
            $(`.pdf-sample[data-id="${id}"] #pdf-viewer`).attr('data', pdf_links[id][0].url);
    } else {
        $(`.document-page-button.next[data-invoice="${id}"]`).hide();
        $(`.document-page-button.prev[data-invoice="${id}"]`).hide();
    }
}

const calculateNewQuantity = (e) => {
    const parent = $(e.target).parents('.product-new.active');
    const quantity_packing = $(parent).find('.qty_packing').val();
    const quantity_per_packing = $(parent).find('.qty_per_packing').val();
    let quantity = 0;

    if(quantity_packing && quantity_per_packing)
        quantity = quantity_per_packing * quantity_packing;

    $(parent).find('#qty').val(quantity).trigger('blur');
}

const calculateEditQuantity = (e) => {
    const parent = $(e.target).parents('.product.active');
    const quantity_packing = $(parent).find('.edit_quantity_packing').val();
    const quantity_per_packing = $(parent).find('.edit_quantity_per_packing').val();
    let quantity = 0;
    if(quantity_packing && quantity_per_packing)
        quantity = quantity_per_packing * quantity_packing;

    $(parent).find('.edit_quantity').val(quantity).trigger('blur');
}

const formatter = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' });

Number.prototype.formatMoney = function(decPlaces, thouSeparator, decSeparator) {
    var n = this,
        decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
        decSeparator = decSeparator == undefined ? "." : decSeparator,
        thouSeparator = thouSeparator == undefined ? "," : thouSeparator,
        sign = n < 0 ? "-" : "",
        i = parseInt(n = Math.abs(+n || 0).toFixed(decPlaces)) + "",
        j = (j = i.length) > 3 ? j % 3 : 0;
    return sign + (j ? i.substr(0, j) + thouSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thouSeparator) + (decPlaces ? decSeparator + Math.abs(n - i).toFixed(decPlaces).slice(2) : "");
}

const calculateSubTotal = (e) => {
    let parent = $(e.target).parents('.product.active');
    if(!parent.length) {
        parent = $(e.target).parents('.product-new.active');
    }
    const quantity = $(parent).find('.edit_quantity').val();
    const price = $(parent).find('.edit_price').val();
    const discount = parseFloat($(parent).find('.edit_discount').val());
    const vat = parseFloat($(parent).find('.edit_vat').val());
    let subtotal = 0;
    let previewSubtotal = 0;
    let previewDiscount = discount;
    let previewVat = 0;

    if(quantity && price || true) {
        previewSubtotal = subtotal = quantity * price;
    }

    if ($('.preview_subtotal').length > 0) {
        previewSubtotal = previewSubtotal.formatMoney(2,'.',',');
        $('.preview_subtotal').html(previewSubtotal);
    }
    if ($('.preview_discount').length > 0) {
        previewDiscount = previewDiscount.formatMoney(2,'.',',');
        $('.preview_discount').html(previewDiscount);
    }

    if(quantity || true) {
        if(subtotal >= 0 && discount > 0) {
            subtotal = (subtotal - discount);
        } else {
            subtotal = (subtotal + discount);
        }
        previewVat = vat/100 * subtotal;

        if($('#invoice_show_vat').prop('checked')) {
            subtotal = subtotal + previewVat;
        }
    }

    if ($('.preview_vat').length > 0) {
        previewVat = previewVat.formatMoney(2,'.',',');
        $('.preview_vat').html(previewVat);
    }

    subtotal = subtotal.formatMoney(2,'.',',');
    $(parent).find('.edit_subtotal').html(subtotal);
    if ($('.preview_total').length > 0) {
        $('.preview_total').html(subtotal);
    }
}


const hideApproved = (e) => {
    $('#approveButton').hide();
}

const checkActive = () => {
     var first_active = 0;
     var selected_delivery = false;
     var set_from_cookie = Cookies.get(collector);

     $('.delivery').each(function(){
        $(this).removeClass('active');

        if(set_from_cookie && set_from_cookie !== 'undefined')
        {
            $('#'+set_from_cookie).addClass('active');
            const id = $('#'+set_from_cookie).data('invoiceId');

            if($('div.delivery-invoices-product--pdf-sample').length)
            {
                if(typeof pdf_links[id] !== 'undefined')
                {
                    setTimeout(function() {
                        if(pdf_links[id].length > 1)
                            $('.document-page-button.next').attr('disabled', false);
                        else
                            $('.document-page-button.next').attr('disabled', true);

                        resetPdfPage(id);
                        showPdf(id);
                    }, 200)
                }
            }
        }
        else if(first_active == 0) {
            var id = $(this).data('invoiceId');
            showPdf(id);
            $(this).addClass('active');
            first_active = 1;
        }
    })

     if(!set_from_cookie || set_from_cookie == 'undefined')
     {
         selected_delivery = $('.delivery.active').attr('id');
         Cookies.set(collector, selected_delivery, { expires: 0.5 });
     }
}

const checkProductGroup = () => {
    $('.edit').each(function(){
        if($(this).find('.potentialTransportCosts').val() == '1')
        {
            $(this).parents('.row-container').find('.row.cloned').addClass('error').after('<div class="error-message">Please check the product group for this product</div>');
        }
    });
}

const checkVat = () => {
    var country = $('#client_country').text();
    if($('#hide_vat').val() == 0)
    {
        if(country == 'The Netherlands' || $('input[name="invoice[show_vat]"]:checked').length > 0)
        {
            $('.edit_vat').each(function(){
                if($(this).attr('data-taxfree') == 1)
                    $(this).parents('.row-container').find('.row.cloned').addClass('success').after('<div class="success-message">This is a tax free delivered product</div>');
                else if($(this).val() == 0 || $(this).val() == '')
                    $(this).parents('.row-container').find('.row.cloned').addClass('error').after('<div class="error-message">Please add the tax for this product</div>');
                else
                    $(this).parents('.row-container').find('.row.cloned').removeClass('error').remove('.error-message');
            });
        }
    }
}

const removeQty = () => {
    var list = document.querySelectorAll('.edit_quantity');
    var n;
    for (n = 0; n < list.length; ++n)
        list[n].value='0';

    $('form[name="collectiveInvoicesUpdate"]').submit();
}

const removeDeliveryQty = (element) => {
    var deliveryNumber = $(element).attr('data-delivery');
    var list = document.querySelectorAll('.edit_quantity_' + deliveryNumber);
    var n;
    for (n = 0; n < list.length; ++n)
        list[n].value='0';

    $('form[name="collectiveInvoicesUpdate"]').submit();
}

export default {
    set: () => {
        if(invoice)
        {
            // set Trigger action
            deliveryTriggers.forEach(element => element.addEventListener('click', setInvoiceDetails));

            // set Form Trigger action
            editTrigger.forEach(element => element.addEventListener('click', setProductFormVisible));

            // Set new Product
            newProductTrigger.on('click', setNewProductFormVisible);

            // Cancel new Product
            cancelProduct.on('click', setCancelProductFormVisible);

            // Calculate quantity
            $('.qty_per_packing, .qty_packing').on('blur', calculateNewQuantity);
            $('.edit_quantity_per_packing, .edit_quantity_packing').on('blur', calculateEditQuantity);

            $('.delivery:not(.custom)').on('click', setDeliveryActive);

            // Caculate subtotal
            $('#invoice_show_vat' ).on('change', function() {
                $('.edit_vat').trigger('blur');
            });
            $('.edit_quantity, .edit_price, .edit_discount, .edit_vat' ).on('blur', calculateSubTotal);
            $('.edit_quantity').on('change', hideApproved);
            $('.quantity-delivery-0').on('click', function() {
                removeDeliveryQty(this);
            })
            $('.quantity-0').on('click', removeQty);

            $('.delivery.custom').on('click', function(e) {
                var el = $(e.currentTarget);
                var id = el.data('id');
                $('.delivery').each(function(){
                    $(this).removeClass('active');
                })
                $(e.currentTarget).addClass('active');
                $('.delivery-invoices-product--pdf-sample').hide();
                resetPdfPage(id);
                showPdf(id);
            })

            // set First element active
            if(new_invoice == false)
            {
                if(Cookies.get(collector) && Cookies.get(collector) !== 'undefined')
                {
                    var element = Cookies.get(collector);
                    var id = element.replace('product_parent_', '');
                    var edit_element = element.replace('parent', 'edit');
                    $('#'+edit_element).addClass('active');
                    showPdf(id)
                }
                else
                {
                    const [productDetailFirst] = collectiveInvoice;
                    if(productDetailFirst) {
                        setDetailScreen(document.querySelector(productDetailFirst.elementId));
                        showPdf(productDetailFirst.id);
                    }
                }
            }

            $("#billing_date").datepicker({
                changeMonth: true,
                changeYear: true,
                dateFormat : 'dd-mm-yy',
            });

            $('.edit_vat').on('change', function() {
                var companyId = $('#companyId').val();
                var country = $('#client_country').text().trim();
                if(companyId == 1 && country == 'The Netherlands')
                    if($(this).val() !== 0 || $(this).val() !== '')
                        $('#invoice_show_vat').prop('checked', true);
                if(companyId == 2 && country == 'USA')
                    if($(this).val() !== 0 || $(this).val() !== '')
                        $('#invoice_show_vat').prop('checked', true);
                if(companyId == 3 && country == 'Canada')
                    if($(this).val() !== 0 || $(this).val() !== '')
                        $('#invoice_show_vat').prop('checked', true);
            });

            checkActive();
            checkProductGroup();
            checkVat();

            // Change address
            clientSelector();

            // show package
            showPackage();
        }
    }
}
