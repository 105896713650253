'use strict'

const addCcRow = () => {
	$( document ).ready(function() {
        $(".btn-end > span").click(function( event ) {
            var rowLimit = 5;
            var inputRow = $(event.target).closest(".row");
            var rowType = $(event.target).closest(".btn-end").children("input").attr("name");
            var newRow = $('<div/>', {'class': 'row'}).append($('<div/>')).append($('<div/>').append($('<input/>', {'type': 'email'}).attr('name', rowType)));
            if($('input[name="'+rowType+'"]').length >= rowLimit){
                return;
            }
            inputRow.after(newRow);
        });
    });
}

const addToRow = () => {
	$( document ).ready(function() {
        $(".email-btn-end > span").click(function( event ) {
            var rowLimit = 5;
            var inputRow = $(event.target).closest(".row");
            var rowType = $(event.target).closest(".email-btn-end").children("input").attr("name");
            var newRow = $('<div/>', {'class': 'row'}).append($('<div/>')).append($('<div/>').append($('<input/>', {'type': 'email'}).attr('name', rowType).attr('autocomplete', rowType)));
            if($('input[name="'+rowType+'"]').length > rowLimit){
                return;
            }
            inputRow.after(newRow);
        });

        $(".email-cc-btn-end > span").click(function( event ) {
            var rowLimit = 3;
            var inputRow = $(event.target).closest(".row");
            var rowType = $(event.target).closest(".email-cc-btn-end").children("input").attr("name");
            var newRow = $('<div/>', {'class': 'row'}).append($('<div/>')).append($('<div/>').append($('<input/>', {'type': 'email'}).attr('name', rowType).attr('autocomplete', rowType)));
            if($('input[name="'+rowType+'"]').length > rowLimit){
                return;
            }
            inputRow.after(newRow);
        });

        $(".email-btn-end-client > span").click(function( event ) {
            var rowLimit = 5;
            var inputRow = $(event.target).closest(".row");
            var rowType = $(event.target).closest(".email-btn-end-client").children("input").attr("name");
            var count = 1;
            count = $('.email_client').length;
            if(count < rowLimit)
            {
            	if(rowType.includes('_us')) {
                    rowType = rowType.slice(0, -4) + (count+1) + '_us';
                } else {
                    rowType = rowType.slice(0, -1) + (count+1);
                }
            	var newRow = $('<div/>', {'class': 'row'}).append($('<div/>')).append($('<div/>').append($('<input/>', {'type': 'email', 'class': 'email_client'}).attr('name', rowType).attr('autocomplete', rowType)));
            	inputRow.after(newRow);
            }
            if($('input[name="'+rowType+'"]').length >= rowLimit){
                return;
            }
            inputRow.after(newRow);
        });

        $(".email-cc-btn-end-client > span").click(function( event ) {
            var rowLimit = 3;
            var inputRow = $(event.target).closest(".row");
            var rowType = $(event.target).closest(".email-cc-btn-end-client").children("input").attr("name");
            var count = 1;
            count = $('.email_cc_client').length;
            if(count < rowLimit)
            {
                if(rowType.includes('_us')) {
                    rowType = rowType.slice(0, -4) + (count+1) + '_us';
                } else {
                    rowType = rowType.slice(0, -1) + (count+1);
                }
                var newRow = $('<div/>', {'class': 'row'}).append($('<div/>')).append($('<div/>').append($('<input/>', {'type': 'email', 'class': 'email_cc_client'}).attr('name', rowType).attr('autocomplete', rowType)));
                inputRow.after(newRow);
            }
            if($('input[name="'+rowType+'"]').length >= rowLimit){
                return;
            }
            inputRow.after(newRow);
        });
    });
}

module.exports = {
    set: () => addCcRow(),
    set_to: () => addToRow()
}
