'use strict'

global.$ = global.jQuery = require('jquery');
global.Cookies = require('js-cookie');

import 'jquery-ui-dist/jquery-ui.min.js';
import 'jquery-ui-dist/jquery-ui.min.css';
import 'tooltipster/dist/css/tooltipster.bundle.min.css';
import navigation from './navigation/navigation.js';
import invoice from './invoice/invoice.js';
import deliveries from './deliveries/deliveries.js';
import approved from './approved/approved.js';
import filter from './filter/filter.js';
import { popup } from './popup/popup.js';
import expandable from './expandable/expandable.js';
import multiSelector from './multi-selector/multi-selector.js';
import { selectbox } from './selectbox/selectbox.js';
import clientSelectbox from './invoice/new';
import dataTableClicker  from './dataTable/clicker.js';
import selector from './document/selector.js';
import MultipleSelect from 'multiple-select-js';
import mailer from './mailer/mailer.js';

window.dt = require('datatables.net');
require('./dataTable/defaults');
require('tooltipster');

// Default selectboxes, on page load
selectbox('select[data-choices="1"]');
clientSelectbox();

// set navigation
navigation.set();
mailer.set();
mailer.set_to();

// Deliveries
deliveries.set();

// Filter
filter.set();

// Multi Selector
multiSelector.set();

selector.set();

// Popup
popup.setDefaultTriggers();

dataTableClicker();

$( document ).ready(function() {
    $('table.data-table').show();
    $('.tooltip').tooltipster({});

    // invoice
	invoice.set();
	// Approved
	approved.set();

    $('body').on('click', '.document-button', function(e){
	    var source = $(this).data('url');
	    $('#pdf-viewer').attr('data', 'https://www.b-shipment.com/e2/'+source);
	});

    $('body').on('click', '.mail-button', function(e){
	    var source = $(this).data('url');
	    $('#pdf-viewer').attr('data', source);
	});

	$('body').on('click', '.add-delivery', function(e) {
		$('body').find('.popup-inner').addClass('no-overflow');
	});

	$('body').on('click', '.no-overflow .adddeliveryclose, .popup-close a', function(e) {
		$('body').find('.popup-inner').removeClass('no-overflow');
	});

	/*$('body').on('click', '#add-exemption', function(e) {
        $(".datepicker").datepicker({
            changeMonth: true,
            changeYear: true,
            dateFormat : 'dd-mm-yy',
        });
	});*/
});
